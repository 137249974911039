import { Component, OnInit } from '@angular/core';
import {config} from './particles.config';
declare var particlesJS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'agilani.me';

  ngOnInit(): void {
    particlesJS('particles-js', config, null);
  }

}
